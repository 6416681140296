// inital button data
export const initialPlans = [
    {
        name: "Stellar 1 step",
        amount: "$6,000",
        fee: "$65",
        planId: 38
    },
    {
        name: "Stellar 1 step",
        amount: "$15,000",
        fee: "$129",
        planId: 39
    },
    {
        name: "Stellar 1 step",
        amount: "$25,000",
        fee: "$219",
        planId: 40
    },
    {
        name: "Stellar 1 step",
        amount: "$50,000",
        fee: "$329",
        planId: 41
    },
    {
        name: "Stellar 1 step",
        amount: "$100,000",
        fee: "$569",
        planId: 42
    },
    {
        name: "Stellar 1 step",
        amount: "$200,000",
        fee: "$1,099",
        planId: 43
    }
];
// initial data
export const initialFeatures = [
    {
        name: "15% Profit Share From Challenge Phase",
        availability: ["$90", "$225", "$375", "$750", "$1,500", "$3,000"]
    },
    {
        name: "Profit Target",
        availability: ["10%", "10%", "10%", "10%", "10%", "10%"]
    },
    {
        name: "Maximum Daily Loss",
        availability: ["3%", "3%", "3%", "3%", "3%", "3%"]
    },
    {
        name: "Maximum Overall Loss",
        availability: ["6%", "6%", "6%", "6%", "6%", "6%"]
    },
    {
        name: "Balance Based Drawdown",
        availability: [true, true, true, true, true, true]
    },
    {
        name: "Minimum Trading",
        availability: [
            "2 Days",
            "2 Days",
            "2 Days",
            "2 Days",
            "2 Days",
            "2 Days"
        ]
    },
    {
        name: "Profit Split Upto",
        availability: ["95%", "95%", "95%", "95%", "95%", "95%"]
    },
    {
        name: "News Trading",
        availability: [true, true, true, true, true, true]
    },
    {
        name: "First Reward",
        availability: [
            "5 Days",
            "5 Days",
            "5 Days",
            "5 Days",
            "5 Days",
            "5 Days"
        ]
    }
];
// desktop data
export const buttonList = [
    {
        id: 1,
        button_name: "Stellar",
        pathname: "stellar",
        subButtons: [
            {
                id: 11,
                button_name: "1-Step",
                newPlans: [
                    {
                        name: "Stellar 1 step",
                        amount: "$6,000",
                        fee: "$65",
                        planId: 38
                    },
                    {
                        name: "Stellar 1 step",
                        amount: "$15,000",
                        fee: "$129",
                        planId: 39
                    },
                    {
                        name: "Stellar 1 step",
                        amount: "$25,000",
                        fee: "$219",
                        planId: 40
                    },
                    {
                        name: "Stellar 1 step",
                        amount: "$50,000",
                        fee: "$329",
                        planId: 41
                    },
                    {
                        name: "Stellar 1 step",
                        amount: "$100,000",
                        fee: "$569",
                        planId: 42
                    },
                    {
                        name: "Stellar 1 step",
                        amount: "$200,000",
                        fee: "$1,099",
                        planId: 43
                    }
                ],
                newFeatures: [
                    {
                        name: "15% Profit Share From Challenge Phase",
                        availability: [
                            "$90",
                            "$225",
                            "$375",
                            "$750",
                            "$1,500",
                            "$3,000"
                        ]
                    },
                    {
                        name: "Profit Target",
                        availability: ["10%", "10%", "10%", "10%", "10%", "10%"]
                    },
                    {
                        name: "Maximum Daily Loss",
                        availability: ["3%", "3%", "3%", "3%", "3%", "3%"]
                    },
                    {
                        name: "Maximum Overall Loss",
                        availability: ["6%", "6%", "6%", "6%", "6%", "6%"]
                    },
                    {
                        name: "Balance Based Drawdown",
                        availability: [true, true, true, true, true, true]
                    },
                    {
                        name: "Minimum Trading",
                        availability: [
                            "2 Days",
                            "2 Days",
                            "2 Days",
                            "2 Days",
                            "2 Days",
                            "2 Days"
                        ]
                    },
                    {
                        name: "Profit Split Upto",
                        availability: ["95%", "95%", "95%", "95%", "95%", "95%"]
                    },
                    {
                        name: "News Trading",
                        availability: [true, true, true, true, true, true]
                    },
                    {
                        name: "First Reward",
                        availability: [
                            "5 Days",
                            "5 Days",
                            "5 Days",
                            "5 Days",
                            "5 Days",
                            "5 Days"
                        ]
                    }
                ]
            },
            {
                id: 12,
                button_name: "2-Step",
                newPlans: [
                    {
                        name: "Stellar 2 step",
                        amount: "$6,000",
                        fee: "$59",
                        planId: 30
                    },
                    {
                        name: "Stellar 2 step",
                        amount: "$15,000",
                        fee: "$119",
                        planId: 31
                    },
                    {
                        name: "Stellar 2 step",
                        amount: "$25,000",
                        fee: "$199",
                        planId: 32
                    },
                    {
                        name: "Stellar 2 step",
                        amount: "$50,000",
                        fee: "$299",
                        planId: 33
                    },
                    {
                        name: "Stellar 2 step",
                        amount: "$100,000",
                        fee: "$549",
                        planId: 34
                    },
                    {
                        name: "Stellar 2 step",
                        amount: "$200,000",
                        fee: "$999",
                        planId: 35
                    }
                ],
                newFeatures: [
                    {
                        name: "15% Profit Share From Challenge Phase",
                        availability: [
                            "$117",
                            "$292.50",
                            "$487.50",
                            "$975",
                            "$1,950",
                            "$3,900"
                        ]
                    },
                    {
                        name: "Profit Target",
                        availability: [
                            "P1: 8%, P2: 5%",
                            "P1: 8%, P2: 5%",
                            "P1: 8%, P2: 5%",
                            "P1: 8%, P2: 5%",
                            "P1: 8%, P2: 5%",
                            "P1: 8%, P2: 5%"
                        ]
                    },
                    {
                        name: "Maximum Daily Loss",
                        availability: ["5%", "5%", "5%", "5%", "5%", "5%"]
                    },
                    {
                        name: "Maximum Overall Loss",
                        availability: ["10%", "10%", "10%", "10%", "10%", "10%"]
                    },
                    {
                        name: "Balance Based Drawdown",
                        availability: [true, true, true, true, true, true]
                    },
                    {
                        name: "Minimum Trading",
                        availability: [
                            "5 Days",
                            "5 Days",
                            "5 Days",
                            "5 Days",
                            "5 Days",
                            "5 Days"
                        ]
                    },
                    {
                        name: "Profit Split Upto",
                        availability: ["95%", "95%", "95%", "95%", "95%", "95%"]
                    },
                    {
                        name: "News Trading",
                        availability: [true, true, true, true, true, true]
                    },
                    {
                        name: "First Reward",
                        availability: [
                            "Monthly",
                            "Monthly",
                            "Monthly",
                            "Monthly",
                            "Monthly",
                            "Monthly"
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        button_name: "Evaluation",
        pathname: "evaluation",
        newPlans: [
            { name: "Evaluation", amount: "$6,000", fee: "$49", planId: 21 },
            { name: "Evaluation", amount: "$15,000", fee: "$99", planId: 10 },
            { name: "Evaluation", amount: "$25,000", fee: "$199", planId: 11 },
            { name: "Evaluation", amount: "$50,000", fee: "$299", planId: 12 },
            { name: "Evaluation", amount: "$100,000", fee: "$549", planId: 13 },
            { name: "Evaluation", amount: "$200,000", fee: "$999", planId: 14 }
        ],
        newFeatures: [
            {
                name: "15% Profit Share From Challenge Phase",
                availability: [
                    "$135",
                    "$337.50",
                    "$562.50",
                    "$1,125",
                    "$2,250",
                    "$4,500"
                ]
            },
            {
                name: "Profit Target",
                availability: [
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%"
                ]
            },
            {
                name: "Maximum Daily Loss",
                availability: ["5%", "5%", "5%", "5%", "5%", "5%"]
            },
            {
                name: "Maximum Overall Loss",
                availability: ["10%", "10%", "10%", "10%", "10%", "10%"]
            },
            {
                name: "Balance Based Drawdown",
                availability: [true, true, true, true, true, true]
            },
            {
                name: "Minimum Trading",
                availability: [
                    "5 Days",
                    "5 Days",
                    "5 Days",
                    "5 Days",
                    "5 Days",
                    "5 Days"
                ]
            },
            {
                name: "Profit Split Upto",
                availability: ["95%", "95%", "95%", "95%", "95%", "95%"]
            },
            {
                name: "News Trading",
                availability: [true, true, true, true, true, true]
            },
            {
                name: "First Reward",
                availability: [
                    "Monthly",
                    "Monthly",
                    "Monthly",
                    "Monthly",
                    "Monthly",
                    "Monthly"
                ]
            }
        ]
    },
    {
        id: 3,
        button_name: "Express",
        pathname: "express",
        subButtons: [
            {
                id: 31,
                button_name: "Consistency",
                newPlans: [
                    {
                        name: "Express Consistency",
                        amount: "$6,000",
                        fee: "$49",
                        planId: 22
                    },
                    {
                        name: "Express Consistency",
                        amount: "$15,000",
                        fee: "$99",
                        planId: 8
                    },
                    {
                        name: "Express Consistency",
                        amount: "$25,000",
                        fee: "$199",
                        planId: 15
                    },
                    {
                        name: "Express Consistency",
                        amount: "$50,000",
                        fee: "$299",
                        planId: 7
                    },
                    {
                        name: "Express Consistency",
                        amount: "$100,000",
                        fee: "$549",
                        planId: 5
                    },
                    {
                        name: "Express Consistency",
                        amount: "$200,000",
                        fee: "$999",
                        planId: 20
                    }
                ],
                newFeatures: [
                    {
                        name: "15% Profit Share From Challenge Phase",
                        availability: [
                            "$225",
                            "$562.50",
                            "$937.50",
                            "$1,875",
                            "$3,750",
                            "$7,500"
                        ]
                    },
                    {
                        name: "Profit Target",
                        availability: ["25%", "25%", "25%", "25%", "25%", "25%"]
                    },
                    {
                        name: "Maximum Daily Loss",
                        availability: ["5%", "5%", "5%", "5%", "5%", "5%"]
                    },
                    {
                        name: "Maximum Overall Loss",
                        availability: ["10%", "10%", "10%", "10%", "10%", "10%"]
                    },
                    {
                        name: "Balance Based Drawdown",
                        availability: [true, true, true, true, true, true]
                    },
                    {
                        name: "Minimum Trading",
                        availability: [
                            "10 Days",
                            "10 Days",
                            "10 Days",
                            "10 Days",
                            "10 Days",
                            "10 Days"
                        ]
                    },
                    {
                        name: "Profit Split Upto",
                        availability: ["95%", "95%", "95%", "95%", "95%", "95%"]
                    },
                    {
                        name: "News Trading",
                        availability: [false, false, false, false, false, false]
                    },
                    {
                        name: "First Reward",
                        availability: [
                            "Monthly",
                            "Monthly",
                            "Monthly",
                            "Monthly",
                            "Monthly",
                            "Monthly"
                        ]
                    }
                ]
            },
            {
                id: 32,
                button_name: "Non-Consistency",
                newPlans: [
                    {
                        name: "Express Non-Consistency",
                        amount: "$6,000",
                        fee: "$59",
                        planId: 23
                    },
                    {
                        name: "Express Non-Consistency",
                        amount: "$15,000",
                        fee: "$119",
                        planId: 16
                    },
                    {
                        name: "Express Non-Consistency",
                        amount: "$25,000",
                        fee: "$229",
                        planId: 17
                    },
                    {
                        name: "Express Non-Consistency",
                        amount: "$50,000",
                        fee: "$379",
                        planId: 18
                    },
                    {
                        name: "Express Non-Consistency",
                        amount: "$100,000",
                        fee: "$699",
                        planId: 19
                    }
                ],
                newFeatures: [
                    {
                        name: "15% Profit Share From Challenge Phase",
                        availability: [
                            "$225",
                            "$562.50",
                            "$937.50",
                            "$1,875",
                            "$3,750"
                        ]
                    },
                    {
                        name: "Profit Target",
                        availability: ["25%", "25%", "25%", "25%", "25%"]
                    },
                    {
                        name: "Maximum Daily Loss",
                        availability: ["5%", "5%", "5%", "5%", "5%"]
                    },
                    {
                        name: "Maximum Overall Loss",
                        availability: ["10%", "10%", "10%", "10%", "10%"]
                    },
                    {
                        name: "Balance Based Drawdown",
                        availability: [true, true, true, true, true]
                    },
                    {
                        name: "Minimum Trading",
                        availability: [
                            "10 Days",
                            "10 Days",
                            "10 Days",
                            "10 Days",
                            "10 Days"
                        ]
                    },
                    {
                        name: "Profit Split Upto",
                        availability: ["95%", "95%", "95%", "95%", "95%"]
                    },
                    {
                        name: "News Trading",
                        availability: [false, false, false, false, false]
                    },
                    {
                        name: "First Reward",
                        availability: [
                            "Monthly",
                            "Monthly",
                            "Monthly",
                            "Monthly",
                            "Monthly"
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        button_name: "Stellar Lite",
        pathname: "stellar-lite",
        newPlans: [
            { name: "stellar lite", amount: "$5,000", fee: "$32", planId: 44 },
            { name: "stellar lite", amount: "$10,000", fee: "$59", planId: 45 },
            {
                name: "stellar lite",
                amount: "$25,000",
                fee: "$139",
                planId: 46
            },
            {
                name: "stellar lite",
                amount: "$50,000",
                fee: "$229",
                planId: 47
            },
            {
                name: "stellar lite",
                amount: "$100,000",
                fee: "$399",
                planId: 48
            }
        ],
        newFeatures: [
            {
                name: "Profit Target",
                availability: [
                    "P1: 8%, P2: 4%",
                    "P1: 8%, P2: 4%",
                    "P1: 8%, P2: 4%",
                    "P1: 8%, P2: 4%",
                    "P1: 8%, P2: 4%"
                ]
            },
            {
                name: "Maximum Daily Loss",
                availability: ["4%", "4%", "4%", "4%", "4%"]
            },
            {
                name: "Maximum Overall Loss",
                availability: ["8%", "8%", "8%", "8%", "8%"]
            },
            {
                name: "Balance Based Drawdown",
                availability: [true, true, true, true, true]
            },
            {
                name: "Minimum Trading",
                availability: ["5 Days", "5 Days", "5 Days", "5 Days", "5 Days"]
            },
            {
                name: "Profit Split Upto",
                availability: ["95%", "95%", "95%", "95%", "95%"]
            },
            {
                name: "News Trading",
                availability: [true, true, true, true, true]
            },
            {
                name: "First Reward",
                availability: [
                    "21 Days",
                    "21 Days",
                    "21 Days",
                    "21 Days",
                    "21 Days"
                ]
            }
        ]
    }
];
// mobile data
export const data = {
    Stellar: {
        "1-Step": {
            plans: [
                {
                    name: "Stellar 1 step",
                    amount: "$6,000",
                    fee: "$65",
                    planId: 38
                },
                {
                    name: "Stellar 1 step",
                    amount: "$15,000",
                    fee: "$129",
                    planId: 39
                },
                {
                    name: "Stellar 1 step",
                    amount: "$25,000",
                    fee: "$219",
                    planId: 40
                },
                {
                    name: "Stellar 1 step",
                    amount: "$50,000",
                    fee: "$329",
                    planId: 41
                },
                {
                    name: "Stellar 1 step",
                    amount: "$100,000",
                    fee: "$569",
                    planId: 42
                },
                {
                    name: "Stellar 1 step",
                    amount: "$200,000",
                    fee: "$1,099",
                    planId: 43
                }
            ],
            features: [
                {
                    name: "15% Profit Share From Challenge Phase",
                    availability: [
                        "$90",
                        "$225",
                        "$375",
                        "$750",
                        "$1,500",
                        "$3,000"
                    ]
                },
                {
                    name: "Profit Target",
                    availability: ["10%", "10%", "10%", "10%", "10%", "10%"]
                },
                {
                    name: "Maximum Daily Loss",
                    availability: ["3%", "3%", "3%", "3%", "3%", "3%"]
                },
                {
                    name: "Maximum Overall Loss",
                    availability: ["6%", "6%", "6%", "6%", "6%", "6%"]
                },
                {
                    name: "Balance Based Drawdown",
                    availability: [true, true, true, true, true, true]
                },
                {
                    name: "Minimum Trading",
                    availability: [
                        "2 Days",
                        "2 Days",
                        "2 Days",
                        "2 Days",
                        "2 Days",
                        "2 Days"
                    ]
                },
                {
                    name: "Profit Split Upto",
                    availability: ["95%", "95%", "95%", "95%", "95%", "95%"]
                },
                {
                    name: "News Trading",
                    availability: [true, true, true, true, true, true]
                },
                {
                    name: "First Reward",
                    availability: [
                        "5 Days",
                        "5 Days",
                        "5 Days",
                        "5 Days",
                        "5 Days",
                        "5 Days"
                    ]
                }
            ]
        },
        "2-Step": {
            plans: [
                {
                    name: "Stellar 2 step",
                    amount: "$6,000",
                    fee: "$59",
                    planId: 30
                },
                {
                    name: "Stellar 2 step",
                    amount: "$15,000",
                    fee: "$119",
                    planId: 31
                },
                {
                    name: "Stellar 2 step",
                    amount: "$25,000",
                    fee: "$199",
                    planId: 32
                },
                {
                    name: "Stellar 2 step",
                    amount: "$50,000",
                    fee: "$299",
                    planId: 33
                },
                {
                    name: "Stellar 2 step",
                    amount: "$100,000",
                    fee: "$549",
                    planId: 34
                },
                {
                    name: "Stellar 2 step",
                    amount: "$200,000",
                    fee: "$999",
                    planId: 35
                }
            ],
            features: [
                {
                    name: "15% Profit Share From Challenge Phase",
                    availability: [
                        "$117",
                        "$292.50",
                        "$487.50",
                        "$975",
                        "$1,950",
                        "$3,900"
                    ]
                },
                {
                    name: "Profit Target",
                    availability: [
                        "P1: 8%, P2: 5%",
                        "P1: 8%, P2: 5%",
                        "P1: 8%, P2: 5%",
                        "P1: 8%, P2: 5%",
                        "P1: 8%, P2: 5%",
                        "P1: 8%, P2: 5%"
                    ]
                },
                {
                    name: "Maximum Daily Loss",
                    availability: ["5%", "5%", "5%", "5%", "5%", "5%"]
                },
                {
                    name: "Maximum Overall Loss",
                    availability: ["10%", "10%", "10%", "10%", "10%", "10%"]
                },
                {
                    name: "Balance Based Drawdown",
                    availability: [true, true, true, true, true, true]
                },
                {
                    name: "Minimum Trading",
                    availability: [
                        "5 Days",
                        "5 Days",
                        "5 Days",
                        "5 Days",
                        "5 Days",
                        "5 Days"
                    ]
                },
                {
                    name: "Profit Split Upto",
                    availability: ["95%", "95%", "95%", "95%", "95%", "95%"]
                },
                {
                    name: "News Trading",
                    availability: [true, true, true, true, true, true]
                },
                {
                    name: "First Reward",
                    availability: [
                        "Monthly",
                        "Monthly",
                        "Monthly",
                        "Monthly",
                        "Monthly",
                        "Monthly"
                    ]
                }
            ]
        }
    },
    Evaluation: {
        plans: [
            { name: "Evaluation", amount: "$6,000", fee: "$49", planId: 21 },
            { name: "Evaluation", amount: "$15,000", fee: "$99", planId: 10 },
            { name: "Evaluation", amount: "$25,000", fee: "$199", planId: 11 },
            { name: "Evaluation", amount: "$50,000", fee: "$299", planId: 12 },
            { name: "Evaluation", amount: "$100,000", fee: "$549", planId: 13 },
            { name: "Evaluation", amount: "$200,000", fee: "$999", planId: 14 }
        ],
        features: [
            {
                name: "15% Profit Share From Challenge Phase",
                availability: [
                    "$135",
                    "$337.50",
                    "$562.50",
                    "$1,125",
                    "$2,250",
                    "$4,500"
                ]
            },
            {
                name: "Profit Target",
                availability: [
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%",
                    "P1: 10%, P2: 5%"
                ]
            },
            {
                name: "Maximum Daily Loss",
                availability: ["5%", "5%", "5%", "5%", "5%", "5%"]
            },
            {
                name: "Maximum Overall Loss",
                availability: ["10%", "10%", "10%", "10%", "10%", "10%"]
            },
            {
                name: "Balance Based Drawdown",
                availability: [true, true, true, true, true, true]
            },
            {
                name: "Minimum Trading",
                availability: [
                    "5 Days",
                    "5 Days",
                    "5 Days",
                    "5 Days",
                    "5 Days",
                    "5 Days"
                ]
            },
            {
                name: "Profit Split Upto",
                availability: ["95%", "95%", "95%", "95%", "95%", "95%"]
            },
            {
                name: "News Trading",
                availability: [true, true, true, true, true, true]
            },
            {
                name: "First Reward",
                availability: [
                    "Monthly",
                    "Monthly",
                    "Monthly",
                    "Monthly",
                    "Monthly",
                    "Monthly"
                ]
            }
        ]
    },
    Express: {
        Consistency: {
            plans: [
                {
                    name: "Express Consistency",
                    amount: "$6,000",
                    fee: "$49",
                    planId: 22
                },
                {
                    name: "Express Consistency",
                    amount: "$15,000",
                    fee: "$99",
                    planId: 8
                },
                {
                    name: "Express Consistency",
                    amount: "$25,000",
                    fee: "$199",
                    planId: 15
                },
                {
                    name: "Express Consistency",
                    amount: "$50,000",
                    fee: "$299",
                    planId: 7
                },
                {
                    name: "Express Consistency",
                    amount: "$100,000",
                    fee: "$549",
                    planId: 5
                },
                {
                    name: "Express Consistency",
                    amount: "$200,000",
                    fee: "$999",
                    planId: 20
                }
            ],
            features: [
                {
                    name: "15% Profit Share From Challenge Phase",
                    availability: [
                        "$225",
                        "$562.50",
                        "$937.50",
                        "$1,875",
                        "$3,750",
                        "$7,500"
                    ]
                },
                {
                    name: "Profit Target",
                    availability: ["25%", "25%", "25%", "25%", "25%", "25%"]
                },
                {
                    name: "Maximum Daily Loss",
                    availability: ["5%", "5%", "5%", "5%", "5%", "5%"]
                },
                {
                    name: "Maximum Overall Loss",
                    availability: ["10%", "10%", "10%", "10%", "10%", "10%"]
                },
                {
                    name: "Balance Based Drawdown",
                    availability: [true, true, true, true, true, true]
                },
                {
                    name: "Minimum Trading",
                    availability: [
                        "10 Days",
                        "10 Days",
                        "10 Days",
                        "10 Days",
                        "10 Days",
                        "10 Days"
                    ]
                },
                {
                    name: "Profit Split Upto",
                    availability: ["95%", "95%", "95%", "95%", "95%", "95%"]
                },
                {
                    name: "News Trading",
                    availability: [false, false, false, false, false, false]
                },
                {
                    name: "First Reward",
                    availability: [
                        "Monthly",
                        "Monthly",
                        "Monthly",
                        "Monthly",
                        "Monthly",
                        "Monthly"
                    ]
                }
            ]
        },
        "Non-Consistency": {
            plans: [
                {
                    name: "Express Non-Consistency",
                    amount: "$6,000",
                    fee: "$59",
                    planId: 23
                },
                {
                    name: "Express Non-Consistency",
                    amount: "$15,000",
                    fee: "$119",
                    planId: 16
                },
                {
                    name: "Express Non-Consistency",
                    amount: "$25,000",
                    fee: "$229",
                    planId: 17
                },
                {
                    name: "Express Non-Consistency",
                    amount: "$50,000",
                    fee: "$379",
                    planId: 18
                },
                {
                    name: "Express Non-Consistency",
                    amount: "$100,000",
                    fee: "$699",
                    planId: 19
                }
            ],
            features: [
                {
                    name: "15% Profit Share From Challenge Phase",
                    availability: [
                        "$225",
                        "$562.50",
                        "$937.50",
                        "$1,875",
                        "$3,750"
                    ]
                },
                {
                    name: "Profit Target",
                    availability: ["25%", "25%", "25%", "25%", "25%"]
                },
                {
                    name: "Maximum Daily Loss",
                    availability: ["5%", "5%", "5%", "5%", "5%"]
                },
                {
                    name: "Maximum Overall Loss",
                    availability: ["10%", "10%", "10%", "10%", "10%"]
                },
                {
                    name: "Balance Based Drawdown",
                    availability: [true, true, true, true, true]
                },
                {
                    name: "Minimum Trading",
                    availability: [
                        "10 Days",
                        "10 Days",
                        "10 Days",
                        "10 Days",
                        "10 Days"
                    ]
                },
                {
                    name: "Profit Split Upto",
                    availability: ["95%", "95%", "95%", "95%", "95%"]
                },
                {
                    name: "News Trading",
                    availability: [false, false, false, false, false]
                },
                {
                    name: "First Reward",
                    availability: [
                        "Monthly",
                        "Monthly",
                        "Monthly",
                        "Monthly",
                        "Monthly"
                    ]
                }
            ]
        }
    },
    StellarLite: {
        plans: [
            { name: "stellar lite", amount: "$5,000", fee: "$32", planId: 44 },
            { name: "stellar lite", amount: "$10,000", fee: "$59", planId: 45 },
            {
                name: "stellar lite",
                amount: "$25,000",
                fee: "$139",
                planId: 46
            },
            {
                name: "stellar lite",
                amount: "$50,000",
                fee: "$229",
                planId: 47
            },
            {
                name: "stellar lite",
                amount: "$100,000",
                fee: "$399",
                planId: 48
            }
        ],
        features: [
            {
                name: "Profit Target",
                availability: [
                    "P1: 8%, P2: 4%",
                    "P1: 8%, P2: 4%",
                    "P1: 8%, P2: 4%",
                    "P1: 8%, P2: 4%",
                    "P1: 8%, P2: 4%"
                ]
            },
            {
                name: "Maximum Daily Loss",
                availability: ["4%", "4%", "4%", "4%", "4%"]
            },
            {
                name: "Maximum Overall Loss",
                availability: ["8%", "8%", "8%", "8%", "8%"]
            },
            {
                name: "Balance Based Drawdown",
                availability: [true, true, true, true, true]
            },
            {
                name: "Minimum Trading",
                availability: ["5 Days", "5 Days", "5 Days", "5 Days", "5 Days"]
            },
            {
                name: "Profit Split Upto",
                availability: ["95%", "95%", "95%", "95%", "95%"]
            },
            {
                name: "News Trading",
                availability: [true, true, true, true, true]
            },
            {
                name: "First Reward",
                availability: [
                    "21 Days",
                    "21 Days",
                    "21 Days",
                    "21 Days",
                    "21 Days"
                ]
            }
        ]
    }
};